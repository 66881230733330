// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems60001: JSSThemeDefaultBannerItemsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, text2, black, primary, warning },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      buttonsWrapperCasinoCarousel: {
        margin: 'auto 0 0 0',
      },
      textWrapperSportsbookCarousel: {
        display: 'flex',
      },
      headerCasinoCarousel: {
        fontSize: '95px',
        lineHeight: '111px',
        fontWeight: 700,
        textAlign: 'start',
        fontFamily: 'Roboto',
      },
      descriptionRoot: {
        fontSize: '40px',
        fontWeight: 700,
        textAlign: 'center',
      },
      bannerContentRoot: {
        color: white,
        padding: margins.xm,
      },
      bannerContentFill: {
        padding: '0',
      },
      bannerContentSportsbookCarousel: {
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        height: '100%',
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentCasinoCarousel: {
        padding: '28px 20px',
        justifyContent: 'start',
      },
      bannerContentPromo: {
        padding: [margins.md],
      },
      headerSportsbookCarousel: {
        fontSize: '35px',
        fontWeight: 'bold',
        color: primary,
      },
      headerPromo: {
        fontSize: '26px',
        lineHeight: 'normal',
        textShadow: 'none',
        fontWeight: 'bold',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.xxl,
        color: primary,
        padding: [8, 9, 0],
        fontWeight: 'bold',
      },
      descriptionCasinoCarousel: {
        fontSize: 40,
        lineHeight: '46px',
        color: white,
        padding: 0,
        marginBottom: 35,
        textAlign: 'start',
        fontFamily: 'Roboto',
        fontWeight: 700,
      },
      descriptionSportsbookSide: {
        padding: '0 0 5px',
        textShadow: 'none',
        fontSize: fontSizes.lg,
        maxWidth: '100%',
        fontWeight: 900,
        textAlign: 'start',
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.lg,
        lineHeight: '20px',
        padding: '0',
      },
      textWrapperFill: {
        padding: 0,
        zIndex: 1,
      },
      headerSportsbookSide: {
        textShadow: 'none',
        fontSize: 35,
        fontWeight: '900',
        lineHeight: 1.2,
      },
      buttonsWrapperSportsbookCarousel: {
        margin: [margins.md, 0, 0, 0],
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: [0, margins.xs],
        marginRight: '0',
      },
      imgContainer: {
        height: '120px',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        width: 'auto',
        padding: '0',
        backgroundColor: white,
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        alignItems: 'start',
        minHeight: 100,
      },
      header: {
        textShadow: `none`,
      },
      description: {
        textShadow: `none`,
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: white,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: black,
      },
      descriptionTeams: {
        color: black,
      },
    },
    SideBanner: {
      containerWithContent: {
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
        backgroundSize: '100%',
        backgroundPosition: 'center 85px',
        '&::before': {
          width: '100%',
          background: warning,
          height: 85,
          top: '0',
          display: 'block',
          content: '""',
          position: 'absolute',
        },
      },
    },
    BannerItemCasinoCarousel: {
      heightFix: {
        height: 300,
        '@media (min-width: 1800px)': {
          height: 400,
        },
      },
      contentWrapper: {
        height: '100%',
        width: '100%',
        background: `linear-gradient(90deg,
          rgba(0, 0, 0, 0.5)
          0%,
          rgba(196, 196, 196, 0) 100%)`,
      },
      mobile: {
        height: '200px',
        '&:after': {
          background: `linear-gradient( to right,
            rgb(23, 42, 10) 0%,
            rgba(10, 31, 50, 0) 15%,
            rgba(10, 31, 50, 0) 100%)`,
        },
      },
    },
  };
};
